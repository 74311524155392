<template>
    <div>
        <headLine msg='双选会统计'></headLine>
        <commonExport ref="export" :exportUrl='exportUrl'></commonExport>
        <el-tabs type="border-card" @tab-click='tabClick'>
            <el-tab-pane label="校区双选会总数据">
                <ul class="sum">                 
                    <li v-for="(item,index) in sumAllArr" :key="'info'+index">
                        <h3>{{item.value}}</h3>
                        <span>{{item.name}}</span>
                    </li>
                    <li v-for="(v,i) in sumArr" :key="i">
                        <h3>{{v.value}}<span v-show="v.percent">%</span></h3>
                        <span>{{v.name}}</span>
                    </li>                
                </ul>
                <tm-table v-bind:columns="columns1" :dataSource="tableData1" :hasIndex="true"> 
                </tm-table>
            </el-tab-pane>
            <el-tab-pane label="单场双选会数据">
                <tm-table v-bind:columns="columns2" :dataSource="tableData2" :hasIndex="true"> 
                </tm-table>
                <paging v-show="tableData2.length>0" :totalpage="totalpage"
                @getInitList="getInitList_2" ref="pages" v-on:handle="handle">
                </paging> 
            </el-tab-pane>
        </el-tabs>
          
    </div>
</template>
<script>
import {columnPropList} from '../../service/config.js'
let sumAllArrs=[
    {name:"举办双选会总次数",key:"dcCount",value:0},
    {name:"参会企业总量",key:"companyCount",value:0},
    {name:"发布岗位总量",key:"jobCount",value:0},
]
let sumArrs=[
    
    {name:"简历总投递量",key:"deliveryNum",value:0},
    {name:"简历通过量",key:"resumePassNum",value:0},
    {name:"简历通过率",key:"resumePassPoint",value:0,percent:true,
    num1_key:'resumePassNum',num2_key:'deliveryNum'},
    {name:"实际面试量",key:"interviewNum",value:0},
    {name:"实际赴约率",key:"toInterviewPoint",value:0,percent:true,
    num1_key:'interviewNum',num2_key:'resumePassNum'},
    {name:"面试通过量",key:"passNum",value:0},
    {name:"面试通过率",key:"passPoint",value:0,percent:true,
    num1_key:'passNum',num2_key:'interviewNum'}
]
let basicList=[
    {
        hasSort:true,
        prop:'companyNum',
        label:'参会企业(家)',
        width:'130'
    },
    {
        hasSort:true,
        prop:'jobNum',
        label:'岗位总量',
        width:'110'
    } 
]
export default {
    data() {
        return {
            tableData1:[],
            userId:null,
            sumArr:[],
            sumAllArr:[],
            tableData2:[],
            totalpage:null,
            current:0,
            columns1:[
                {
                    hasSort:false,
                    prop:'orgName',
                    label:'校区名称'
                },
                {
                    hasSort:true,
                    prop:'dcNum',
                    label:'举办双选会(次)'
                },
                
            ],
            columns2:[
                {
                    hasSort:false,
                    prop:'dcName',
                    label:'双选会名称'
                }
            ],
            exportUrl:''
        }
    },
    methods: {
        //tab切换换下载地址
        tabClick(row){
            this.exportUrl=row.index=='1'?`/tea/dc/statistics/export?userId=${this.userId}`
            :`/tea/org/dc/statistics/export?userId=${this.userId}`
        },
        handle(val){
            this.current=val 
        }, 
        getInitList_1(){
            let url=`/tea/org/dc/statistics?userId=${this.userId}`  
            this.$htp.get_(url).then((res)=>{
                let data=res.data
                this.handleTableData(data,1)
                // this.tableData1=data              
                
                this.getTargetSum(data)
            }) 
        },
        handleTableData(data,type){
            data.forEach(e=>{
                e.resumePassPoint=e.resumePassPoint+'%'
                e.toInterviewPoint=e.toInterviewPoint+'%'
                e.passPoint=e.passPoint+'%'
            })
            if(type==1){
                this.tableData1=data
            }else{
                this.tableData2=data
            }
            
        },
        getTargetSum(data){
            let sumArr=JSON.parse(JSON.stringify(sumArrs))
            sumArr.forEach(v=>{
                data.forEach(e=>{
                    v.value+=e[v.key]
                })
            })
            sumArr.forEach(e=>{
                if(e.percent){
                    e.value=this.handlePercent(sumArr,e.num1_key,e.num2_key)
                }
            })
            this.sumArr=sumArr 
            this.getSumData()
        },
        handlePercent(sumArr,num1_key,num2_key){
            let num1,num2;
            sumArr.forEach(e=>{
                if(e.key==num1_key){
                    num1=e.value
                }
                if(e.key==num2_key){
                    num2=e.value
                }
            })
            return ((num1/num2)*100).toFixed(0)
        },
        async getSumData(){
            let url=`/tea/statistics/all`  
            let sumData=await this.$htp.get_(url)
            for(let i in sumData.data){
                sumAllArrs.forEach(e=>{
                    if(e.key==i){
                        e.value=sumData.data[i]
                    }
                })
            }
            this.sumAllArr=sumAllArrs
            this.getInitList_2(0)
            
        },
        getInitList_2(current){
            let url=`/tea/dc/statistics?userId=${this.userId}&page=${current}`  
            this.$htp.get_(url).then((res)=>{
                let data=res.data
                this.totalpage=data.total
                this.getTableData_2(data.rows)
            }) 
        },
        getTableData_2(rows){
            let arr=[]
            rows.forEach(e=>{
                let obj={}
                let {dcName,companyNum,jobNum}=e
                let zpComStatic={...e.zpComStatic}
                let zpStatic={dcName,companyNum,jobNum}
                obj=Object.assign(zpStatic,zpComStatic)
                arr.push(obj)
            })
            this.handleTableData(arr,2)
        }
    },
    
    mounted(){
        let arr1=this.columns1.concat(basicList,columnPropList)
        this.columns1=arr1
        let arr2=this.columns2.concat(basicList,columnPropList)
        this.columns2=arr2
        this.userId=sessionStorage.getItem('id')
        //校区双选会总数据
        this.exportUrl=`/tea/org/dc/statistics/export?userId=${this.userId}`
        this.getInitList_1()
    }
}
</script>
<style lang="scss" scoped>
@import '../../assets/css/dbStatic.scss';
.export{
    margin-top: 10px;
}
</style>